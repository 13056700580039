<style>
.add-owner {
    float: right;
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-supplier-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="sm" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="supplier.id === null">Register Supplier</h5>
                <h5 v-if="supplier.id !== null">Edit Supplier</h5>
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="sm" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="type" label="Type">
                                    <validation-provider #default="{ errors }" name="type" rules="">
                                        <b-form-select id="type" name="type" v-model="supplier.type" :state="errors.length > 0 ? false : null" size="sm">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option :value="0">Individual</b-form-select-option>
                                            <b-form-select-option :value="1">Company</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.type">{{ serverErrors.type[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="supplier.type === 1">
                            <b-col cols="4">
                                <b-form-group label-for="abbreviation" label="Abbreviation">
                                    <validation-provider #default="{ errors }" name="abbreviation" rules="">
                                        <b-form-input
                                            id="abbreviation"
                                            name="abbreviation"
                                            v-model="supplier.abbreviation"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.abbreviation">{{ serverErrors.abbreviation[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="8">
                                <b-form-group label-for="name" label="Name">
                                    <validation-provider #default="{ errors }" name="name" rules="">
                                        <b-form-input
                                            id="name"
                                            name="name"
                                            v-model="supplier.name"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="supplier.type === 0">
                            <b-col cols="4">
                                <b-form-group label-for="firstName" label="First Name">
                                    <validation-provider #default="{ errors }" name="firstName" rules="">
                                        <b-form-input
                                            id="firstName"
                                            name="firstName"
                                            v-model="supplier.firstName"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.firstName">{{ serverErrors.firstName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group label-for="middleName" label="Middle Name">
                                    <validation-provider #default="{ errors }" name="middleName" rules="">
                                        <b-form-input
                                            id="middleName"
                                            name="middleName"
                                            v-model="supplier.middleName"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.middleName">{{ serverErrors.middleName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group label-for="lastName" label="Last Name">
                                    <validation-provider #default="{ errors }" name="lastName" rules="">
                                        <b-form-input
                                            id="lastName"
                                            name="lastName"
                                            v-model="supplier.lastName"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.lastName">{{ serverErrors.lastName[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="4" v-if="supplier.type === 0">
                                <b-form-group label-for="gender" label="Gender">
                                    <validation-provider #default="{ errors }" name="gender" rules="">
                                        <b-form-select id="gender" name="gender" v-model="supplier.gender" :state="errors.length > 0 ? false : null" size="sm">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option value="female">Female</b-form-select-option>
                                            <b-form-select-option value="male">Male</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.gender">{{ serverErrors.gender[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="8">
                                <div>
                                    <b-card-text class="mb-0">
                                        Active?
                                    </b-card-text>
                                    <b-form-checkbox
                                        checked="true"
                                        class="custom-control-success"
                                        name="check-button"
                                        switch
                                        v-model="supplier.isActive"
                                    >
                                        <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </div>
                            </b-col>
                        </b-row>

                        <!-- <pre>
                            {{ selected }}, {{ supplier }}
                        </pre> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Register Supplier</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(name)="data">
                    {{ data.item.name.toUpperCase() }}
                </template>

                <template #cell(type)="data">
                    <span v-if="data.item.type === 1">Company</span>
                    <span v-else>Individual</span>
                </template>

                <template #cell(isActive)="data">
                    <span v-if="data.item.isActive">Active</span>
                    <span v-else>Blocked</span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardText, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'
import suppliersStoreModule from '@/views/cromis/supplier/suppliersStoreModule'
import useSuppliersList from '@/views/cromis/supplier/useSuppliersList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BCardText, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner, BFormCheckbox, BFormCheckboxGroup,
        ValidationObserver, ValidationProvider,
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },

    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)

        const options = ref([
            { text: 'Is Active', value: 'isActive' },
        ])

        const selected = ref([]);

        const supplier = ref({
            id: null,
            supplier_id: null,
            supplier_type: null,
            type: null,
            abbreviation: null,
            name: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: null,
            isActive: true,
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-supplier'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, suppliersStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

        })

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
        } = useSuppliersList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            supplier.value = {
                id: item.id,
                supplier_id: item.supplier_id,
                supplier_type: item.supplier_type,
                type: item.type,
                abbreviation: item.abbreviation,
                name: item.name,
                firstName: item.firstName,
                middleName: item.middleName,
                lastName: item.lastName,
                gender: item.gender,
                isActive: item.isActive,
            }

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            supplier.value = {
                id: null,
                supplier_id: null,
                supplier_type: null,
                type: null,
                abbreviation: null,
                name: null,
                firstName: null,
                middleName: null,
                lastName: null,
                gender: null,
                isActive: true,
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (supplier.value.id === null || supplier.value.id === 0)
                handleCreate()
            else
                handleUpdate(supplier.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-supplier/create', supplier.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Supplier has been added successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-supplier/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to supplier ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-supplier/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            supplier,
            options,
            selected,

            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-select.scss';
</style>